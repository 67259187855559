import logo from "../assets/images/logo.svg";

const Logo = () => {
  return (
    <h2
      style={{
        color: "var(--primary-900)",
        fontWeight: 600,
      }}
    >
      ReactAD.PRO
    </h2>
  );
};

export default Logo;
