import axios from "../utils/axios";

// const fakeBM = {
//   name: "Hana Manager",
//   business_users: {
//     data: [
//       {
//         id: "122179672118006574",
//         name: "Admin Hana",
//         business: {
//           id: "6602184693202057",
//           name: "LLC Manager 360958",
//         },
//         role: "ADMIN",
//       },
//       {
//         id: "122179672118006574",
//         name: "Hung Nguyen",
//         business: {
//           id: "6602184693202057",
//           name: "LLC Manager 360958",
//         },
//         role: "ADMIN",
//       },
//     ],
//     paging: {
//       cursors: {
//         before:
//           "QVFIUmlVT1ozOE5vNkxOSUV2QWxva0xBUnV0bk1RU1hZAOHRnY3hkci15X3BGV1ZANZAll6aUhGS2xFRWJZAZAENiLTJKOVdmdFVjRHFjVlhZAa1hBMTlTWjBBZAXN3",
//         after:
//           "QVFIUmlVT1ozOE5vNkxOSUV2QWxva0xBUnV0bk1RU1hZAOHRnY3hkci15X3BGV1ZANZAll6aUhGS2xFRWJZAZAENiLTJKOVdmdFVjRHFjVlhZAa1hBMTlTWjBBZAXN3",
//       },
//     },
//   },
//   verification_status: "not_verified",
//   created_time: "2023-08-23T18:12:55+0000",
//   id: "6602184693202057",
// };

// const fakeData = {
//   data: [
//     {
//       name: "Hana Manager",
//       business_users: {
//         data: [
//           {
//             id: "122179672118006574",
//             name: "Admin Hana",
//             business: {
//               id: "6602184693202057",
//               name: "LLC Manager 360958",
//             },
//             role: "ADMIN",
//           },
//           {
//             id: "122179672118006574",
//             name: "Hung Nguyen",
//             business: {
//               id: "6602184693202057",
//               name: "LLC Manager 360958",
//             },
//             role: "ADMIN",
//           },
//         ],
//         paging: {
//           cursors: {
//             before:
//               "QVFIUmlVT1ozOE5vNkxOSUV2QWxva0xBUnV0bk1RU1hZAOHRnY3hkci15X3BGV1ZANZAll6aUhGS2xFRWJZAZAENiLTJKOVdmdFVjRHFjVlhZAa1hBMTlTWjBBZAXN3",
//             after:
//               "QVFIUmlVT1ozOE5vNkxOSUV2QWxva0xBUnV0bk1RU1hZAOHRnY3hkci15X3BGV1ZANZAll6aUhGS2xFRWJZAZAENiLTJKOVdmdFVjRHFjVlhZAa1hBMTlTWjBBZAXN3",
//           },
//         },
//       },
//       verification_status: "not_verified",
//       created_time: "2023-08-23T18:12:55+0000",
//       id: "6602184693202057",
//     },
//     {
//       name: "ReactAD.Pro",
//       business_users: {
//         data: [
//           {
//             id: "122168479556026980",
//             name: "Tuan Em",
//             business: {
//               id: "6578820925517948",
//               name: "LLC Manager 520557",
//             },
//             role: "ADMIN",
//           },
//         ],
//         paging: {
//           cursors: {
//             before:
//               "QVFIUjJERWtrZAUx6ZA3d5UXpNb0lDU1gzZAWNzbkQ2TkU2NnB5bDQxY0ZAvMzI5RE9zRmFKcnRvYzFYNE01R2tjVFRPR2podUduUk5jbUNnMEZAfOHhYZAWFMTkln",
//             after:
//               "QVFIUjJERWtrZAUx6ZA3d5UXpNb0lDU1gzZAWNzbkQ2TkU2NnB5bDQxY0ZAvMzI5RE9zRmFKcnRvYzFYNE01R2tjVFRPR2podUduUk5jbUNnMEZAfOHhYZAWFMTkln",
//           },
//         },
//       },
//       verification_status: "not_verified",
//       created_time: "2023-08-23T18:10:27+0000",
//       id: "6578820925517948",
//     },
//     {
//       name: "Pro Ads llc",
//       business_users: {
//         data: [
//           {
//             id: "122178346886011024",
//             name: "100009562741901 100009562741901",
//             business: {
//               id: "6270661743031729",
//               name: "LLC Manager 302825",
//             },
//             role: "ADMIN",
//           },
//         ],
//         paging: {
//           cursors: {
//             before:
//               "QVFIUlB3NlRlR0FkVmRFZA012aGZAPdThJSndVZA2tsbW1IRm1rdzNRZAjFIMmJGcHgyYzg2X2ZAya1N5V1NTZA1pkZATZArNnBHYU82dURlcWMxdEJ4N2NPdDlGellR",
//             after:
//               "QVFIUlB3NlRlR0FkVmRFZA012aGZAPdThJSndVZA2tsbW1IRm1rdzNRZAjFIMmJGcHgyYzg2X2ZAya1N5V1NTZA1pkZATZArNnBHYU82dURlcWMxdEJ4N2NPdDlGellR",
//           },
//         },
//       },
      
//       verification_status: "not_verified",
//       created_time: "2023-08-24T01:40:54+0000",
//       id: "6270661743031729",
//     },
//     {
//       name: "Hung Em Enter",
//       business_users: {
//         data: [
//           {
//             id: "122178146886211024",
//             name: "100009562741901 100009562741901",
//             business: {
//               id: "6270661743031729",
//               name: "LLC Manager 302825",
//             },
//             role: "ADMIN",
//           },
//         ],
//         paging: {
//           cursors: {
//             before:
//               "QVFIUlB3NlRlR0FkVmRFZA012aGZAPdThJSndVZA2tsbW1IRm1rdzNRZAjFIMmJGcHgyYzg2X2ZAya1N5V1NTZA1pkZATZArNnBHYU82dURlcWMxdEJ4N2NPdDlGellR",
//             after:
//               "QVFIUlB3NlRlR0FkVmRFZA012aGZAPdThJSndVZA2tsbW1IRm1rdzNRZAjFIMmJGcHgyYzg2X2ZAya1N5V1NTZA1pkZATZArNnBHYU82dURlcWMxdEJ4N2NPdDlGellR",
//           },
//         },
//       },
      
//       verification_status: "not_verified",
//       created_time: "2023-08-24T01:40:54+0000",
//       id: "6270661743031729",
//     },
//   ],
//   paging: {
//     cursors: {
//       before:
//         "QVFIUjJCX2dnYW5QU3JrbHk5NFlsMnBDUFl5aGdlYUQ4N0dhV0tsQjFJTS1FYW00WlB1TEVGUzJxaGliakxUVzhJM1kwNy1RNDVUdkpRMDRMd2VOdWM1TDdB",
//       after:
//         "QVFIUm5aMU1lVGRoSEJyanNGUzBpQTlhQnRBZAmFCb0w1SERDODNEQ2NaUkdRTVVNOWhZAeXpFMEtPQ3FnZAHdZAbVBkZAVp4OG85X2YtZA0l5WlFfcUZATTmNWUDdR",
//     },
//   },
// };
const getBusinesses = async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return axios.get("/bm").then((res) => res.data);
};

const getBMSelected = (bmID) => {
  return axios.get(`/bm/${bmID}`).then((res) => res.data);
};

const changeRoles = ({ bmID, role }) => {
  return axios.post(`/bm/${bmID}`, `role=${role}`).then((res) => res.data);
};

const BusinessAPI = {
  getBusinesses,
  getBMSelected,
  changeRoles,
};
export default BusinessAPI;
